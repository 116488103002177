<template>
  <!--Container-->
	<div class="container shadow-lg mx-auto bg-white mt-24 md:mt-18 px-2 py-3">
    <div>
      <div class="mb-3">
        <h1 class="text-lg">
          Daftar Barang Kreditan
        </h1>
      </div>
      <router-link :to="{name: 'credit.create'}" class="bg-green-500 rounded text-white px-3 py-2 hover:bg-green-300 inline-block mb-3">Buat Kreditan</router-link>
    </div>

    <div v-if="data.moneyOutside" class="mb-3 px-3 py-2 font-bold bg-green-100 inline-block rounded">
      Total Uang Diluar : {{toRp(data.moneyOutside)}}
    </div>
    <div v-else class="mb-3 px-3 py-2 font-bold bg-green-100 inline-block rounded">
      Tidak ada uang diluar
    </div>

    <div class="py-2 overflow-scroll" v-if="data.products">
      <table class="">
        <thead class="font-bold">
          <tr>
            <td class="border border-gray-400 whitespace-nowrap px-3 py-1">Clients</td>
            <td class="border border-gray-400 whitespace-nowrap px-6 py-1">Nama Barang</td>
            <td class="border border-gray-400 whitespace-nowrap px-3 py-1">Status</td>
            <td class="border border-gray-400 whitespace-nowrap px-3 py-1">Harga Jual</td>
            <td class="border border-gray-400 whitespace-nowrap px-3 py-1">Harga Dasar</td>
            <td class="border border-gray-400 whitespace-nowrap px-3 py-1">Discount Toko</td>
            <td class="border border-gray-400 whitespace-nowrap px-3 py-1">Biaya Admin</td>
            <td class="border border-gray-400 whitespace-nowrap px-3 py-1">Persentase</td>
            <td class="border border-gray-400 whitespace-nowrap px-3 py-1">Tanggal Tagihan</td>
            <td class="border border-gray-400 whitespace-nowrap px-3 py-1">Tindakan</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(product, index) in data.products.data" :key="index" class="hover:bg-yellow-200" :class="product.status === 'on_going' ? 'bg-pink-50' : 'bg-green-50'">
            <td class="border border-gray-400 whitespace-nowrap px-3 py-1">{{product.client.name}}</td>
            <td class="border border-gray-400 px-3 py-1">
              <router-link :to="{name: 'credit.show', params: {id: product.id}}" class="text-yellow-600 hover:text-yellow-500">{{product.title}}</router-link>
            </td>
            <td class="border border-gray-400 whitespace-nowrap px-3 py-1">{{product.status === 'on_going' ? 'Mencicil' : 'Lunas'}}</td>
            <td class="border border-gray-400 whitespace-nowrap px-3 py-1">{{product.selling_price}}</td>
            <td class="border border-gray-400 whitespace-nowrap px-3 py-1">{{product.base_price}}</td>
            <td class="border border-gray-400 whitespace-nowrap px-3 py-1">{{product.discount_base_price}}</td>
            <td class="border border-gray-400 whitespace-nowrap px-3 py-1">{{product.admin_fee}}</td>
            <td class="border border-gray-400 whitespace-nowrap px-3 py-1">{{product.percentage - 100}}%</td>
            <td class="border border-gray-400 whitespace-nowrap px-3 py-1">{{product.duedate}}</td>
            <td class="border border-gray-400 whitespace-nowrap px-3 py-1">
              <router-link :to="{ name: 'credit.edit', params: { id: product.id } }" class="bg-green-500 rounded text-white px-4 py-1 hover:bg-green-300 inline-block">Edit</router-link>
            </td>
          </tr>
        </tbody>
      </table>
      
      <div class="flex justify-end mt-3 flex-wrap" v-if="data.products.links">
        <div v-for="(page, index) in data.products.links" :key="index">
          <span v-on:click="page.url !== null ? getProducts(page.url) : ''" class="border border-gray-400 px-3 py-1 mr-1 mb-1 inline-block rounded cursor-pointer select-none" :class="page.active ? 'cursor-not-allowed bg-white' : 'bg-gray-300'">
              <span v-html="page.label"></span>
          </span>
        </div>
      </div>
    </div>
	</div>
</template>

<script>

import axios from "axios";
import { onMounted, reactive, ref } from "vue"

export default {

  name: 'credit.index',

  setup() {

    // Reactive state
    let data = ref({});

    const currentPage = reactive({
        url: '/api/v1/producs',
    });

    onMounted(() => {
      getProducts(currentPage.url);
    });

    function getProducts(url) {
      // Get data from API
      axios.get(url)
      .then((result) => {
        data.value = result.data;
      }).catch((err) => {
        console.log(err.response.message);
      });      
    }

    function toRp(nominal) {
        return new Intl.NumberFormat('id-ID', {
            style: 'currency', 
            currency: 'IDR' 
        }).format(nominal);
    }

    return { 
      data,
      toRp,
      currentPage,
      getProducts
    }
  
  }


}
</script>