import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue'
import Login from '../views/Auth/Login.vue'
import authStore from '../store/auth'


/**Client Router */
import clientIndex from '../views/Clients/Index.vue'
import clientCreate from '../views/Clients/Create.vue'

/** Credit Router */
import creditIndex from '../views/Credit/Index.vue'
import creditCreate from '../views/Credit/Create.vue'
import creditShow from '../views/Credit/Show.vue'
import creditEdit from '../views/Credit/Edit.vue'

/**Paymet Router */
import paymentCreate from '../views/Payments/Create.vue'

/**Saldo Router */
import saldoIndex from '../views/Saldo/Index.vue'
import saldoTransfer from '../views/Saldo/Transfer.vue'
import saldoWithdraw from '../views/Saldo/Withdraw.vue'
import saldoDeposit from '../views/Saldo/Deposit.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    async beforeEnter(to, from, next) {
      await authStore.dispatch('user')
      if (authStore.state.authStatus) {
        next()        
      } else {
        next({
          name: 'Login'
        })
      }

    }
  },
  {
    path: '/client',
    name: 'client.index',
    component: clientIndex,
    async beforeEnter(to, from, next) {
      await authStore.dispatch('user')
      if (authStore.state.authStatus) {
        next()        
      } else {
        next({
          name: 'Login'
        })
      }

    }
  },
  {
    path: '/client/create',
    name: 'client.create',
    component: clientCreate,
    async beforeEnter(to, from, next) {
      await authStore.dispatch('user')
      if (authStore.state.authStatus) {
        next()        
      } else {
        next({
          name: 'Login'
        })
      }

    }
  },
  {
    path: '/credit',
    name: 'credit.index',
    component: creditIndex,
    async beforeEnter(to, from, next) {
      await authStore.dispatch('user')
      if (authStore.state.authStatus) {
        next()        
      } else {
        next({
          name: 'Login'
        })
      }
    }
  },
  {
    path: '/credit/create',
    name: 'credit.create',
    component: creditCreate,
    async beforeEnter(to, from, next) {
      await authStore.dispatch('user')
      if (authStore.state.authStatus) {
        next()        
      } else {
        next({
          name: 'Login'
        })
      }
    }
  },
  {
    path: '/credit/:id/edit',
    name: 'credit.edit',
    component: creditEdit,
    async beforeEnter(to, from, next) {
      await authStore.dispatch('user')
      if (authStore.state.authStatus) {
        next()        
      } else {
        next({
          name: 'Login'
        })
      }
    }
  },
  {
    path: '/credit/:id',
    name: 'credit.show',
    component: creditShow,
    async beforeEnter(to, from, next) {
      await authStore.dispatch('user')
      if (authStore.state.authStatus) {
        next()        
      } else {
        next({
          name: 'Login'
        })
      }
    }
  },
  {
    path: '/payment/create/:id',
    name: 'payment.create',
    component: paymentCreate,
    async beforeEnter(to, from, next) {
      await authStore.dispatch('user')
      if (authStore.state.authStatus) {
        next()        
      } else {
        next({
          name: 'Login'
        })
      }
    }
  },
  {
    path: '/saldo/',
    name: 'saldo.index',
    component: saldoIndex,
    async beforeEnter(to, from, next) {
      await authStore.dispatch('user')
      if (authStore.state.authStatus) {
        next()        
      } else {
        next({
          name: 'Login'
        })
      }
    }
  },
  {
    path: '/saldo/transfer',
    name: 'saldo.transfer',
    component: saldoTransfer,
    async beforeEnter(to, from, next) {
      await authStore.dispatch('user')
      if (authStore.state.authStatus) {
        next()        
      } else {
        next({
          name: 'Login'
        })
      }
    }
  },
  {
    path: '/saldo/withdraw',
    name: 'saldo.withdraw',
    component: saldoWithdraw,
    async beforeEnter(to, from, next) {
      await authStore.dispatch('user')
      if (authStore.state.authStatus) {
        next()        
      } else {
        next({
          name: 'Login'
        })
      }
    }
  },
  {
    path: '/saldo/deposit',
    name: 'saldo.deposit',
    component: saldoDeposit,
    async beforeEnter(to, from, next) {
      await authStore.dispatch('user')
      if (authStore.state.authStatus) {
        next()        
      } else {
        next({
          name: 'Login'
        })
      }
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
