<template>
  <nav class="flex items-center justify-between flex-wrap bg-gray-800 p-6 fixed w-full z-10 top-0">
		<div class="flex items-center flex-shrink-0 text-white mr-6">
			<a class="text-white no-underline hover:text-white hover:no-underline" href="#">
				<span class="text-2xl pl-2"><i class="em em-grinning"></i> Kredit Asik</span>
			</a>
		</div>

		<div class="block lg:hidden">
			<button v-on:click="hidden" id="nav-toggle" class="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-white hover:border-white">
				<svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
			</button>
		</div>

		<div class="w-full flex-grow lg:flex lg:items-center lg:w-auto pt-6 lg:pt-0" :class="{ 'hidden' : isHidden }" id="nav-content">
			<ul class="list-reset lg:flex justify-end flex-1 items-center" v-on:click="isHidden=true">
				<li class="mr-3">
					<router-link :to="{name : 'Home'}" class="inline-block py-2 px-4 text-white no-underline">Home</router-link>
				</li>
				<li class="mr-3" v-if="authStatus">
					<router-link :to="{ name: 'Dashboard' }" class="inline-block py-2 px-4 text-white no-underline">Dashboard</router-link>
				</li>
				<li class="mr-3" v-if="authStatus">
					<router-link :to="{ name: 'credit.index' }" class="inline-block py-2 px-4 text-white no-underline">Kredit</router-link>
				</li>
				<li class="mr-3" v-if="authStatus">
					<router-link :to="{ name: 'saldo.index' }" class="inline-block py-2 px-4 text-white no-underline">Saldo</router-link>
				</li>
				<li class="mr-3" v-if="authStatus">
					<router-link :to="{ name: 'client.index' }" class="inline-block py-2 px-4 text-white no-underline">Client</router-link>
				</li>
				<li class="mr-3" v-if="authStatus">
					<span class="inline-block py-2 px-4 text-white no-underline">Hi {{user.name}}</span>
					<a href="#" @click.prevent="logout" class="inline-block py-2 px-4 text-white no-underline">Logout</a>
				</li>
				<li class="mr-3" v-if="!authStatus">
					<router-link :to="{ name: 'Login' }" class="inline-block py-2 px-4 text-white no-underline">Login</router-link>
				</li>
			</ul>
		</div>
	</nav>
</template>

<script>
import { computed } from '@vue/runtime-core';
import { useRouter } from 'vue-router'
import authStore from '../store/auth';

  export default {

    name : 'NavBar',

	data() {
		return {
			isHidden: true,
		}
	},

	setup() {

		const authStatus = computed(() => authStore.state.authStatus)
		const user = computed(()=> authStore.state.user)
		const router = useRouter()

		let logout = async () => {
			await authStore.dispatch('logout')
			router.replace({
				name: 'Login'
			})

		}

		return { authStatus, user, logout }

	},

	methods: {
		hidden: function() {
			this.isHidden = !this.isHidden;
		}
	},

  }
</script>

<style>

</style>