<template>
    <div :class="display.popUp ? '' : 'hidden'" class="-mt-4">
      <div class="fixed w-full h-screen bg-black bg-opacity-75 z-10">
        <div class="flex h-screen opacity w-full">
          <div class="m-auto md:w-1/2 w-full px-2 text-gray-700 text-center">
            <div class="bg-white px-4 py-2 rounded relative">
                <div class="static">
                    <div class="absolute bg-gray-400 hover:bg-gray-300 top-0 right-0 text-white hover:text-black px-2 py-1 rounded-full items-center m-2 text-xs cursor-pointer select-none" 
                        v-on:click="display.popUp = false; display.installmentDetail = false; display.installmentDelete = false; display.installmentEdit = false;"
                    >
                        X
                    </div>

                    <!-- Installment Detail -->
                    <div :class="display.installmentDetail ? '' : 'hidden'">
                        <table class="mt-3 text-left">
                            <tr>
                                <td class="px-3 py-1">Nominal</td>
                                <td class="px-3 py-1">{{toRp(installmentDetail.nominal)}}</td>
                            </tr>
                            <tr>
                                <td class="px-3 py-1">Biaya Admin</td>
                                <td class="px-3 py-1">{{toRp(installmentDetail.admin_fee)}}</td>
                            </tr>
                            <tr>
                                <td class="px-3 py-1">Tanggal Bayar</td>
                                <td class="px-3 py-1">{{dateInstallment(installmentDetail.date)}}</td>
                            </tr>
                        </table>
                        <div class="flex justify-end mt-2">
                            <button v-on:click="display.installmentDelete = true; display.installmentDetail = false; display.installmentEdit = false;" class="mr-1 px-3 py-1 bg-red-500 hover:bg-red-400 text-white rounded">
                                Delete
                            </button>
                            <button v-on:click="display.installmentEdit = true; display.installmentDetail = false; display.installmentDelete = false;" class="px-3 py-1 bg-green-500 hover:bg-green-400 text-white rounded">
                                Edit                            
                            </button>
                        </div>
                    </div>

                    <!-- Installment Edit -->
                    <div :class="display.installmentEdit ? '' : 'hidden'">
                        <form @submit.prevent="editInstallment(installmentDetail.id)">
                            <table class="mt-3 text-left">
                                <tr>
                                    <td class="pr-3">Nominal</td>
                                    <td>
                                        <div class="mb-1">
                                            <input type="text" name="paying" id="" class="bg-white px-3 py-2 rounded border border-blue-400" v-model="installment.nominalRp" @input="convertRp">
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="pr-3">Biaya Admin</td>
                                    <td>
                                        <div class="mb-1">
                                            <input type="text" name="paying" id="" class="bg-white px-3 py-2 rounded border border-blue-400" v-model="installment.admin_feeRp" @input="convertRp" autocomplete="off">
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="pr-3">Tanggal</td>
                                    <td>
                                        <div class="mb-1">
                                          <input type="date" class="bg-white px-3 py-2 rounded border border-blue-400" v-model="installment.date">
                                        </div>
                                    </td>
                                </tr>
                            </table>
                            <div class="flex justify-end my-3">
                                <button v-on:click="display.installmentEdit = false; display.installmentDetail = true;" class="bg-red-500 text-white px-3 py-1 rounded mr-1" type="button">Batal</button>
                                <button class="bg-green-500 text-white px-3 py-1 rounded mr-3">Simpan</button>
                            </div>
                        </form>
                    </div>

                    <!-- Installment Delete -->
                    <div :class="display.installmentDelete ? '' : 'hidden'">
                        <div class="my-3">
                            Yakin Dihapus?
                        </div>
                        <div class="my-3">
                            <button v-on:click="display.installmentDelete = false; display.installmentDetail = true;" class="mr-1 px-3 py-1 bg-green-500 hover:bg-green-400 text-white rounded">
                                Batal                            
                            </button>
                            <button v-on:click="deleteInstallment(installmentDetail.id)" class="px-3 py-1 bg-red-500 hover:bg-red-400 text-white rounded">
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container mx-auto mt-24 md:mt-18 px-3">
        <div class="flex h-screen center">
            <div class="m-auto md:w-1/3 w-full">
                <div class="bg-white shadow-lg px-3 py-2 rounded mb-32">
                    <div class="mb-3 py-3">
                        <h1 class="text-center text-lg font-bold">{{product.title}}</h1>
                    </div>
                    <div class="mb-3">
                        <table>
                            <tr>
                                <td class="p-1">Client </td>
                                <td class="p-1" v-if="product.client">{{product.client.name}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">Harga Jual</td>
                                <td class="p-1">{{toRp(product.selling_price)}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">Harga Dasar</td>
                                <td class="p-1">{{toRp(product.base_price)}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">Discount Toko</td>
                                <td class="p-1">{{toRp(product.discount_base_price)}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">Biaya Admin</td>
                                <td class="p-1">{{toRp(product.admin_fee)}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">Tgl Bayar</td>
                                <td class="p-1">{{dateInstallment(product.duedate)}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">Angsuran</td>
                                <td class="p-1">{{product.installment_period}} x @ {{toRp(installment.nominal)}}</td>
                            </tr>
                        </table>
                    </div>
                    <div v-if="product.status === 'on_going'">
                        <div v-if="payment && display.payButton">
                            <div v-if="payment.isPast">
                                <div v-if="payment.isPast.day === 0" class="font-bold bg-yellow-200 text-center py-3 px-2 rounded border border-yellow-700">
                                    <div class="mb-2">
                                        Hari ini jatuh tempo
                                    </div>
                                    <button class="inline-block bg-green-500 hover:bg-green-400 text-white px-4 py-2 rounded" v-on:click="display.payButton = !display.payButton">
                                        Bayar Sekarang
                                    </button>
                                </div>
                                <div v-if="payment.isPast.day < 0" class="font-bold bg-red-200 text-center py-3 px-2 rounded border border-red-700">
                                    <div class="mb-2">
                                        Cicilan sudah telat {{Math.abs(payment.isPast.day)}} hari
                                    </div>
                                    <button class="inline-block bg-green-500 hover:bg-green-400 text-white px-4 py-2 rounded" v-on:click="display.payButton = !display.payButton">
                                        Bayar Sekarang
                                    </button>
                                </div>
                                <div v-if="payment.isPast.day > 0" class="font-bold bg-gray-200 text-center py-3 px-2 rounded border border-gray-700">
                                    <div class="mb-2">
                                        Cicilan berikutnya {{Math.abs(payment.isPast.day)}} hari lagi
                                    </div>
                                    <button class="inline-block bg-green-500 hover:bg-green-400 text-white px-4 py-2 rounded" v-on:click="display.payButton = !display.payButton">
                                        Bayar Lebih Awal
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div v-if="payment && !display.payButton" class="px-3 py-2 bg-green-50 rounded border border-green-100">
                            <form @submit.prevent="createInstallment">
                                <table class="mb-3">
                                    <tr>
                                        <td class="pr-3">Nominal</td>
                                        <td><input type="text" name="paying" id="" class="bg-white px-3 py-2 rounded border border-blue-400" v-model="installment.nominalRp" @input="convertRp"></td>
                                    </tr>
                                    <tr>
                                        <td class="pr-3">Biaya Admin</td>
                                        <td><input type="text" name="paying" id="" class="bg-white px-3 py-2 rounded border border-blue-400" v-model="installment.admin_feeRp" @input="convertRp" autocomplete="off"></td>
                                    </tr>
                                    <tr>
                                        <td class="pr-3">Tanggal</td>
                                        <td><input type="date" class="bg-white px-3 py-2 rounded border border-blue-400" v-model="installment.date"></td>
                                    </tr>
                                </table>
                                <div class="flex justify-end" v-if="!display.loading">
                                    <button type="button" v-on:click="display.payButton = !display.payButton" class="px-3 py-2 bg-red-500 hover:bg-red-400 text-white rounded">Batal</button>
                                    <button class="bg-green-500 text-white px-3 py-2 rounded ml-1 hover:bg-green-400">Bayar</button>
                                </div>
                                <div v-if="display.loading" class="flex justify-end pr-4">
                                    <div class="bg-yellow-500 text-white px-3 py-2 rounded ml-1 inline-block">
                                    Loading....
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div v-if="product.status === 'paid_off'" class="font-bold bg-green-200 text-center py-3 px-2 rounded border border-green-700">
                        Cicilan Sudah Lunas
                    </div>
                    <div class="mt-4">
                        <div class="text-base font-bold mb-1 text-green-800 inline-block items-center">
                            <span class="border-b border-gray-400">Riwayat Cicilan</span> <span class="text-sm border border-red-400 bg-red-100 ml-2 rounded-full px-1">{{payment.count}}X</span>
                        </div>
                        
                        <div class="mb-1">
                            <div class="bg-yellow-100 inline-block px-3 py-1 font-bold">
                                Sisa Angsuran
                                <span class="text-sm border border-red-400 bg-red-100 rounded-full px-1">{{product.installment_period-payment.count}}X</span>
                                {{toRp(payment.remaining)}}
                            </div>
                        </div>

                        <div v-if="installment.history.length > 0" class="border border-green-100 rounded">
                            <div v-for="(history,index) in installment.history" :key="index" class="px-3 py-2 flex justify-between items-center" :class="index % 2 ? 'bg-white' : 'bg-green-100'">
                                {{installment.history.length - index}}).
                                {{dateInstallment(history.date)}},
                                {{toRp(history.nominal)}}
                                <span v-on:click="getInstallment(history.id)" class="bg-yellow-500 hover:bg-yellow-400 px-1 text-white rounded cursor-pointer select-none">
                                    Detil
                                </span>
                            </div>
                        </div>
                        <div v-else class="px-3 py-3 bg-green-100">Belum ada pembayaran cicilan</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import { useRoute } from 'vue-router';
import { reactive, ref } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
export default {

    setup() {
        
        const route = useRoute();

        let product = ref({});

        let payment = ref({});

        let display = ref({
            cretaePayment: false,
            payButton: true,
            loading: false,
            popUp: false,
            installmentDetail: false,
            installmentDelete: false,
            installmentEdit: false,
        });

        const installment = reactive({
            product_id: '',
            nominal: '',
            nominalRp: '',
            admin_fee: '',
            admin_feeRp: '',
            date: '',
            history: [],
        });

        const installmentDetail = ref([]);

        onMounted(()=> {

            getProduct();

        });

        function getProduct() {
            axios.get('/api/v1/producs/' + route.params.id)
            .then((result) => {
                product.value           = result.data.details;
                payment.value           = result.data.payment;
                installment.history     = result.data.details.installment;
                installment.product_id  = result.data.details.id;

                installment.nominal   = result.data.details.selling_price / result.data.details.installment_period;
                installment.nominalRp = installment.nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

                installment.admin_fee   = result.data.details.admin_fee / result.data.details.installment_period;
                installment.admin_feeRp = installment.admin_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

                let today = new Date();
                let dd = String(today.getDate()).padStart(2, '0');
                let mm = String(today.getMonth() + 1).padStart(2, '0');
                let yyyy = today.getFullYear();

                installment.date = yyyy + '-' + mm + '-' + dd;

                convertRp();

            }).catch((err) => {
                console.log(err.response);
            });
        }

        function convertRp() {

            if (installment.nominalRp !== '') {
                installment.nominal = installment.nominalRp.split('.').join("");
                installment.nominal = parseInt(installment.nominal);
                installment.nominalRp = installment.nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            } else {
                installment.nominal = 0;
            }

            if (installment.admin_feeRp !== '') {
                installment.admin_fee = installment.admin_feeRp.split('.').join("");
                installment.admin_fee = parseInt(installment.admin_fee);
                installment.admin_feeRp = installment.admin_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            } else {
                installment.admin_feeRp = 0;
            }

        }

        function dateInstallment(date) {
            const parse = new Date(date);
            const options = {
                weekday: 'short', 
                year: 'numeric', 
                month: 'short', 
                day: 'numeric' 
            };
            return parse.toLocaleDateString('id-ID', options);            
        }

        function toRp(nominal) {
             return new Intl.NumberFormat('id-ID', {
                style: 'currency', 
                currency: 'IDR' 
            }).format(nominal);
        }

        function getInstallment(id) {
            axios.get('/api/v1/installments/' + id)
            .then((result) => {
                installmentDetail.value = result.data
                this.display.popUp = true;
                this.display.installmentDetail = true;
            }).catch((err) => {
                console.log(err.response);
            });
        }

        function deleteInstallment(id) {
            axios.post('/api/v1/installments/' + id,{
                _method: 'DELETE'
            })
            .then((result) => {
                getProduct();
                this.display.popUp = false;
                this.display.installmentDetail = false;
                this.display.installmentDelete = false;
                this.display.installmentEdit = false;
            }).catch((err) => {
                
            });
        }

        function editInstallment(id) {
            let insert = {
                nominal: installment.nominal,
                admin_fee: installment.admin_fee,
                date: installment.date,
                _method: 'PATCH',
            }
            axios.post('/api/v1/installments/' + id, insert)
            .then((result) => {
                getProduct();
                this.display.popUp = false;
                this.display.installmentDetail = false;
                this.display.installmentDelete = false;
                this.display.installmentEdit = false;
            }).catch((err) => {
                
            });
            console.log(insert);
        }

        function createInstallment() {

            /** Next Payment*/
            const date = new Date(this.product.duedate);
            const duedate = new Date(date.setMonth(date.getMonth()+1));
            const dd = String(duedate.getDate()).padStart(2, '0');
            const mm = String(duedate.getMonth() + 1).padStart(2, '0');
            const yyyy = duedate.getFullYear();
            const nextPayment = yyyy + '-' + mm + '-' + dd;

            let insert = {
                product_id : installment.product_id,
                nominal: installment.nominal,
                admin_fee: installment.admin_fee,
                date: installment.date,
                next_payment: nextPayment
            }

            axios.get('/sanctum/csrf-cookie');
            axios.post('/api/v1/installments',insert)
            .then((result) => {
                this.display.loading = true;
                if (result.data.code === 201) {
                    this.display.payButton = !this.display.payButton;
                    this.display.loading = false;
                    getProduct();
                }
            }).catch((err) => {
                console.log(err.response);
            });
        }

        return {
            product,
            payment,
            display,
            installment,
            createInstallment,
            dateInstallment,
            convertRp,
            toRp,
            getInstallment,
            installmentDetail,
            deleteInstallment,
            editInstallment
        }

    },
    
}
</script>