<template>
    <div class="container mx-auto mt-24 md:mt-18 px-3">
        <div class="flex h-screen center">
            <div class="m-auto">
                <form @submit.prevent="login" class="bg-white mb-72 p-6 rounded shadow-lg">
                    <table class="mb-3">
                        <tr>
                            <td class="px-2 py-1"><label for="email">Email</label></td>
                            <td class="px-2 py-1"><input v-model="user.email" type="email" class="bg-gray-100 rounded px-3 py-2" required></td>
                        </tr>
                        <tr v-if="validation.email">
                            <td></td>
                            <td class="px-2 pb-1 text-red-500">{{validation.email[0]}}</td>
                        </tr>
                        <tr class="whitespace-nowrap">
                            <td class="px-2 py-1"><label for="password">Password</label></td>
                            <td class="px-2 py-1"><input v-model="user.password" type="password" class="bg-gray-100 rounded px-3 py-2" required></td>
                        </tr>
                    </table>
                    <div class="flex justify-end px-2" >
                        <button v-if="!check.loading" type="submit" class="px-6 py-2 bg-blue-500 rounded text-white">Login</button>
                        <button v-else type="submit" class="px-6 py-2 bg-yellow-500 rounded text-white pointer-events-none">Loading...</button>
                    </div>
                    <div class="text-red-500 text-center mt-3 bg-red-100 px-3 py-3" v-if="check.internalServerError">
                        Internal Server Error
                    </div>
                    <div class="text-red-500 text-center mt-3 bg-red-100 px-3 py-3" v-if="check.unknowError">
                        Unknow Error
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>

import { reactive, ref } from '@vue/reactivity'
import { useRouter } from 'vue-router'
import authStore from '../../store/auth'

export default {
    
    name: 'Login',

    setup() {

        const router = useRouter()
        const user = reactive({
            email: null,
            password: null
        });

        let check = reactive({
            loading: false,
            internalServerError: false,
            unknowError: false,
        });

        const validation = ref({});

        let login = async () => {
            check.loading = true;
            await authStore.dispatch('login', user)
            .then(() => {                
                router.replace({
                    name: 'Dashboard'
                })
            }).catch((err) => {

                try {                    
                    if (err.response.status === 500) {
                        check.internalServerError = true
                        check.loading = false;
                    }
    
                    if (err.response.status === 422) {                    
                        validation.value = err.response.data.errors;
                        check.loading = false;
                    }
                } catch (error) {
                    check.unknowError = true
                    check.loading = false;
                }


            });
        }

        return { user, login, check, validation }

    }
}

</script>

<style>

</style>