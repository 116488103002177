<template>
    <div class="container mx-auto mt-24 md:mt-18 px-3">
        <div class="flex h-screen center">
            <div class="m-auto md:w-1/3 w-full">
                <div class="bg-white shadow-lg px-3 py-2 rounded mb-32">
                    <div class="mb-3">
                        <div class="my-3">
                            <h1 class="text-center text-lg">Tambah Client</h1>
                        </div>
                        <form @submit.prevent="createClient">
                            <table class="mb-3">
                                <tr>
                                    <td class="px-3">Nama</td>
                                    <td class="pb-1">
                                        <input v-model="client.name" type="text" class="px-3 py-2 rounded w-full bg-blue-100 focus:outline-none">
                                    </td>
                                </tr>
                                <tr v-if="validation.name">
                                    <td></td>
                                    <td class="pb-2 text-red-500">{{validation.name[0]}}</td>
                                </tr>

                                <tr>
                                    <td class="px-3">Pekerjaan</td>
                                    <td class="pb-1">
                                        <input v-model="client.job" type="text" class="px-3 py-2 rounded w-full bg-blue-100 focus:outline-none">
                                    </td>
                                </tr>
                                <tr v-if="validation.job">
                                    <td></td>
                                    <td class="pb-2 text-red-500">{{validation.job[0]}}</td>
                                </tr>

                                <tr>
                                    <td class="px-3">No HP</td>
                                    <td class="pb-1">
                                        <input v-model="client.phone_number" type="number" class="px-3 py-2 rounded w-full bg-blue-100 focus:outline-none">
                                    </td>
                                </tr>
                                <tr v-if="validation.phone_number">
                                    <td></td>
                                    <td class="pb-2 text-red-500">{{validation.phone_number[0]}}</td>
                                </tr>                                

                                <tr>
                                    <td class="px-3">Alamat</td>
                                    <td class="pb-1">
                                        <input v-model="client.address" type="text" class="px-3 py-2 rounded w-full bg-blue-100 focus:outline-none">
                                    </td>
                                </tr>
                                <tr v-if="validation.address">
                                    <td></td>
                                    <td class="pb-2 text-red-500">{{validation.address[0]}}</td>
                                </tr>

                            </table>
                            <div class="flex justify-end px-3">
                                <button class="bg-green-500 hover:bg-green-400 rounded px-3 py-2 text-white">Simpan</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import axios from 'axios'
import { useRouter } from 'vue-router';
import { reactive, ref } from '@vue/reactivity';

export default {
    name: 'client.create',
    setup() {

        const client = reactive({
            name: '',
            job: '',
            phone_number: '',
            address: '',
        });

        const validation = ref([]);

        const router = useRouter();

        function createClient() {    
            axios.get('/sanctum/csrf-cookie')        
            axios.post('/api/v1/clients', client)
            .then(() => {
                router.push({
                    name: 'client.index'
                });
            }).catch((err) => {
                validation.value = err.response.data.errors;
            });
        }

        return {
            client,
            createClient,
            validation
        }
    }
}
</script>