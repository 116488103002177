<template>
    <div class="container mx-auto mt-24 md:mt-18 px-3">
        <div class="flex center">
            <div class="m-auto md:w-1/3 w-full">
                <div class="bg-white shadow-lg px-3 py-2 rounded mb-6">
                    <div class="mb-3 py-3">
                        <h1 class="text-center text-lg">Buat Kreditan</h1>
                    </div>
                    <form @submit.prevent="createCredit" class="mb-3">
                        <table class="mb-3">
                            <tbody class="table w-full">
                                <tr class="py-3">
                                    <td class="px-2">Client</td>
                                    <td>
                                        <div class="mb-1 bg-blue-100 pr-2 rounded">
                                            <select class=" px-3 py-2 rounded w-full bg-blue-100 focus:outline-none" v-model="product.client_id">
                                                <option selected disabled>Pilih Client</option>
                                                <option v-for="(client,index) in clients.data" :key="index" :value="client.id">{{client.name}}</option>
                                            </select>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="validation.client_id">
                                    <td></td>
                                    <td class="pb-2 text-red-500">{{validation.client_id[0]}}</td>
                                </tr>

                                <tr class="pb-3">
                                    <td class="px-2">Produk</td>
                                    <td>
                                        <div class="mb-1">
                                            <textarea name="" id="" rows="2" v-model="product.title" class="bg-blue-100 px-3 py-2 rounded"></textarea>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="validation.title">
                                    <td></td>
                                    <td class="pb-2 text-red-500">{{validation.title[0]}}</td>
                                </tr>

                                <tr class="pb-3">
                                    <td class="px-2">Harga Beli</td>
                                    <td>
                                        <div class="mb-1">
                                        <input type="text" name="produc" class="bg-blue-100 px-3 py-2 rounded" autocomplete="off" v-model="toRp.base_price" @input="countPriceCredit">
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="validation.submit">
                                    <td></td>
                                    <td class="pb-2 text-red-500">Dana Tidak Cukup</td>
                                </tr>
                                <tr v-if="validation.base_price">
                                    <td></td>
                                    <td class="pb-2 text-red-500">{{validation.base_price[0]}}</td>
                                </tr>

                                <tr class="pb-3">
                                    <td class="px-2">Modal</td>
                                    <td>
                                        <div class="mb-1 py-2">
                                        {{toRpString(saldo.capital)}}
                                        </div>
                                    </td>
                                </tr>

                                <tr class="pb-3">
                                    <td class="px-2">Discount</td>
                                    <td>
                                        <div class="mb-1">
                                        <input type="text" name="produc" class="bg-blue-100 px-3 py-2 rounded" autocomplete="off" v-model="toRp.discount_base_price" @input="countPriceCredit">
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="validation.discount_base_price">
                                    <td></td>
                                    <td class="pb-2 text-red-500">{{validation.discount_base_price[0]}}</td>
                                </tr>

                                <tr class="pb-3">
                                    <td class="px-2">DP</td>
                                    <td>
                                        <div class="mb-1">
                                        <input type="text" name="produc" class="bg-blue-100 px-3 py-2 rounded" autocomplete="off" v-model="toRp.down_payment" @input="countPriceCredit">
                                        </div>
                                    </td>
                                </tr>

                                <tr class="pb-3">
                                    <td class="px-2">Biaya Admin</td>
                                    <td>
                                        <div class="mb-1">
                                        <input type="text" name="produc" class="bg-blue-100 px-3 py-2 rounded" autocomplete="off" v-model="toRp.admin_fee" @input="countPriceCredit">
                                        </div>
                                    </td>
                                </tr>

                                <tr class="pb-3">
                                    <td class="px-2">Keuntungan</td>
                                    <td>
                                        <div class="mb-1 bg-blue-100 pr-2 rounded">
                                            <select class="px-3 py-2 rounded w-full bg-blue-100 focus:outline-none" v-model="product.percentage" @change="countPriceCredit">
                                                <option value="125">25%</option>
                                                <option value="135">35%</option>
                                                <option value="145">45%</option>
                                            </select>
                                        </div>
                                    </td>
                                </tr>

                                <tr class="pb-3">
                                    <td class="px-2">Lama Angsuran</td>
                                    <td>
                                        <div class="mb-1 bg-blue-100 pr-2 rounded">
                                            <select class="px-3 py-2 rounded w-full bg-blue-100 focus:outline-none" v-model="product.installment_period" @change="countPriceCredit">
                                                <option value="3">3 Bulan</option>
                                                <option value="4">4 Bulan</option>
                                                <option value="5">5 Bulan</option>
                                                <option value="6">6 Bulan</option>
                                                <option value="7">7 Bulan</option>
                                                <option value="8">8 Bulan</option>
                                                <option value="9">9 Bulan</option>
                                                <option value="10">10 Bulan</option>
                                                <option value="11">11 Bulan</option>
                                                <option value="12">12 Bulan</option>
                                            </select>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="px-2">Jatuh Tempo</td>
                                    <td>
                                        <div class="mb-1 bg-blue-100 pr-2 rounded">
                                            <input type="date" class=" px-3 py-2 rounded w-full bg-blue-100 focus:outline-none" v-model="product.duedate">
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="validation.duedate">
                                    <td></td>
                                    <td class="pb-2 text-red-500">{{validation.duedate[0]}}</td>
                                </tr>

                                <tr class="pb-3">
                                    <td class="px-2">Harga Jual</td>
                                    <td class="">
                                        <div class="px-3 py-2 font-bold">
                                            {{toRp.selling_price === '' ? 'Rp 0,00' : toRp.selling_price}}
                                        </div>
                                    </td>
                                </tr>

                                <tr class="pb-3">
                                    <td class="px-2">Cicilan Bulanan</td>
                                    <td class="">
                                        <div class="px-3 py-2 font-bold">
                                            {{toRp.installment === '' ? 'Rp 0,00' : toRp.installment}}
                                        </div>
                                    </td>
                                </tr>

                                <tr class="pb-3">
                                    <td class="px-2">Keuntungan</td>
                                    <td class="">
                                        <div class="px-3 py-2 font-bold">
                                            {{toRp.profit === '' ? 'Rp 0,00' : toRp.profit}}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="flex justify-end pr-3">
                                                        <button class="bg-gray-500 hover:bg-gray-400 rounded px-3 py-2 text-white pointer-events-none" disabled v-if="validation.submit">Dana Tidak Cukup</button>
                            <button class="bg-green-500 hover:bg-green-400 rounded px-3 py-2 text-white" v-else>Buat Kreditan</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from "axios";
import { onMounted, ref, reactive } from "vue";
import { useRouter } from 'vue-router';

export default {

    setup() {

        let clients  = ref([]);
        
        const saldo = ref([]);

        const router = useRouter();
        
        const product = reactive({
            client_id: '',
            title: '',
            base_price: '',
            discount_base_price: '',
            down_payment: '',
            admin_fee: '',
            selling_price: '',
            duedate: '',
            percentage: '135',
            installment_period: '10'
        });

        const toRp = reactive({
            base_price: '',
            discount_base_price: '',
            down_payment: '',
            admin_fee: '',
            selling_price: '',
            installment: '',
            profit: '',
        });

        const validation = ref([]);

        onMounted(() => {

            // Get data clients from API
            axios.get('/api/v1/clients?list=all')
            .then((result) => {
                clients.value = result.data;
            }).catch((err) => {
                console.log(err.response.data);
            });

            getSaldo();

        });

        function getSaldo() {
            axios.get('/api/v1/saldos')
            .then((result) => {
                saldo.value = result.data;
            }).catch((err) => {
                console.log(err.response);                
            });
        }

        function toRpString(nominal) {
            return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(nominal);
        }

        function countPriceCredit() {

            if (toRp.base_price !== '') {
                product.base_price = toRp.base_price.split('.').join("");
                product.base_price = parseInt(product.base_price);
                toRp.base_price = product.base_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            } else {
                product.base_price = 0;
            }

            if (toRp.discount_base_price !== '') {
                product.discount_base_price = toRp.discount_base_price.split('.').join("");
                product.discount_base_price = parseInt(product.discount_base_price);
                toRp.discount_base_price = product.discount_base_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            } else {
                product.discount_base_price = 0;
            }

            if (toRp.down_payment !== '') {
                product.down_payment = toRp.down_payment.split('.').join("");
                product.down_payment = parseInt(product.down_payment);
                toRp.down_payment = product.down_payment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            } else {
                product.down_payment = 0;
            }

            if (toRp.admin_fee !== '') {
                product.admin_fee = toRp.admin_fee.split('.').join("");
                product.admin_fee = parseInt(product.admin_fee);
                toRp.admin_fee = product.admin_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            } else {
                product.admin_fee = 0;
            }

            /** Check ketersediaan saldo*/ 
            if ((product.base_price - product.down_payment + product.admin_fee) > this.saldo.capital) {
                validation.value = {
                    submit: 'Dana Tidak cukup'
                };
            } else {
                validation.value = {}
            }

            // Hitung harga jual
            product.selling_price = product.percentage / 100 * (product.base_price - product.down_payment + product.admin_fee);

            // Pembualan 50.000 ke atas
            let installment_period = (product.selling_price / product.installment_period) / 5000;
            installment_period = Math.round(installment_period);
            installment_period = installment_period * 5000;

            // Hitung harga jual fixed base on cicilan
            product.selling_price = installment_period * product.installment_period;

            // Harga Jual
            toRp.selling_price = new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(product.selling_price);
            // Cicilan bulanan
            toRp.installment = new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(installment_period);
            // Keuntungan
            toRp.profit = new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(product.selling_price - product.base_price - product.admin_fee + product.discount_base_price);

        }
        
        function createCredit() {

            axios.get('/sanctum/csrf-cookie')
            axios.post('/api/v1/producs', product)
            .then(() => {
                router.push({
                    name: 'credit.index'
                });
            }).catch((err) => {
                validation.value = err.response.data.errors;
            });

        }

        return {
            ref,
            clients,
            product,
            validation,
            router,
            createCredit,
            countPriceCredit,
            toRp,
            saldo,
            toRpString
        }
    }
}

</script>