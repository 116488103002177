<template>
  <!--Container-->
	<div class="container shadow-lg mx-auto bg-white mt-24 md:mt-18 px-2 py-3">
        <div class="mb-3">
            <h1 class="text-lg text-center">
                Saldo Kredit Asik
            </h1>
        </div>

        <table class="mb-3">
            <tr>
                <td class="px-3 py-1">Total Saldo</td>
                <td class="px-3 py-1">{{toRp(wallet.total)}}</td>
            </tr>
            <tr>
                <td class="px-3 py-1">Modal</td>
                <td class="px-3 py-1">{{toRp(wallet.capital)}}</td>
            </tr>
            <tr>
                <td class="px-3 py-1">Tabungan</td>
                <td class="px-3 py-1">{{toRp(wallet.savings)}}</td>
            </tr>
        </table>

        <div class="mb-3">
            <router-link :to="{name: 'saldo.deposit'}" class="bg-green-500 text-white px-3 py-2 inline-block rounded hover:bg-green-400 mr-1">Masukan Dana</router-link>
            <router-link :to="{name: 'saldo.withdraw'}" class="bg-green-500 text-white px-3 py-2 inline-block rounded hover:bg-green-400 mr-1">Tarik Dana</router-link>
            <router-link :to="{name: 'saldo.transfer'}" class="bg-green-500 text-white px-3 py-2 inline-block rounded hover:bg-green-400 mr-1">Transfer</router-link>
        </div>

        <div class="md:w-3/4 w-full">
            <div class="overflow-x-scroll">                
                <table class="w-full">
                    <thead class="font-bold">
                        <tr>
                            <td class="border border-gray-400 py-2 bg-green-100 px-3">Dompet</td>
                            <td class="border border-gray-400 py-2 bg-green-100 px-3">Nominal</td>
                            <td class="border border-gray-400 py-2 bg-green-100 px-10">Information</td>
                            <td class="border border-gray-400 py-2 bg-green-100 px-3">Dibuat</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(saldo, index) in saldos.data" :key="index">
                            <td class="border border-gray-400 px-3 py-1">{{saldo.wallet === 'savings' ? 'Tabungan' : 'Modal'}}</td>
                            <td class="border border-gray-400 px-3 py-1 whitespace-nowrap">{{toRp(saldo.nominal)}}</td>
                            <td class="border border-gray-400 px-3 py-1">{{saldo.information}}</td>
                            <td class="border border-gray-400 px-3 py-1 whitespace-nowrap">{{datString(saldo.created_at)}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="flex justify-end mt-3 flex-wrap">
                <div v-for="(page, index) in saldos.links" :key="index">
                    <span v-on:click="page.url !== null ? getSaldo(page.url) : ''" class="border border-gray-400 px-3 py-1 mr-1 mb-1 inline-block rounded cursor-pointer select-none" :class="page.active ? 'cursor-not-allowed bg-white' : 'bg-gray-300'">
                        <span v-html="page.label"></span>
                    </span>
                </div>
            </div>
        </div>

    </div>
</template>
<script>

import axios from 'axios'
import { reactive, ref } from '@vue/reactivity';

export default {

    setup() {
        
        const saldos = ref({});

        const currentPage = reactive({
            url: '/api/v1/saldos?page=1',
        });

        const wallet = ref({
            total: '',
            savings: '',
            capital: ''
        });

        getSaldo(currentPage.url);

        function getSaldo(page) {
            axios.get(page)
            .then((result) => {
                saldos.value = result.data.saldos;
                wallet.value.total = result.data.total;
                wallet.value.savings = result.data.savings;
                wallet.value.capital = result.data.capital;
            }).catch((err) => {
                console.log(err.response);
            });
        }

        function toRp(nominal) {
            return new Intl.NumberFormat('id-ID', {
                style: 'currency', 
                currency: 'IDR' 
            }).format(nominal);
        }

        function datString(date) {
            const parse = new Date(date);
            const options = {
                weekday: 'long', 
                year: 'numeric', 
                month: 'long', 
                day: 'numeric' 
            };
            return parse.toLocaleDateString('id-ID', options);            
        }

        return {
            saldos,
            toRp,
            datString,
            currentPage,
            getSaldo,
            wallet
        }
    }
    
}
</script>