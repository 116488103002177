import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import authStore from './store/auth'

axios.defaults.withCredentials = true

if (process.env.VUE_APP_LOCAL === 'true') {
    axios.defaults.baseURL = 'http://localhost:8000';
} else {
    axios.defaults.baseURL = 'https://api.kredit.tegarjaya.com';
}


// Current using TailwindCSS
import './index.css'

authStore.dispatch('user').then(() => {
    
    createApp(App).use(store).use(router).mount('#app')

})
