<template>
    <div class="container mx-auto mt-24 md:mt-18 px-3">
        <div class="flex h-screen center">
            <div class="m-auto md:w-1/3 w-full">
                <div class="bg-white shadow-lg px-3 py-2 rounded mb-32">
                    <div class="mb-3 py-3">
                        <h1 class="text-center text-lg">Buat Pembayaran</h1>
                    </div>
                    <form>
                        <table class="mb-3">
                            <tr v-if="product.details">
                                <td class="pr-3">Produk</td>
                                <td class="pb-1">
                                    <div class="px-3 py-2">
                                        {{product.details.title}}
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="product.details">
                                <td class="pr-3">Client</td>
                                <td class="pb-1">
                                    <div class="px-3 py-2" v-if="product.details.client">
                                        {{product.details.client.name}}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="pr-3">Nominal</td>
                                <td class="pb-1"><input type="number" class="bg-blue-100 px-3 py-2 rounded" autocomplete="off"></td>
                            </tr>
                            <tr>
                                <td class="pr-3">Tanggal</td>
                                <td class="pb-1"><input type="date" class="px-3 py-2 rounded w-full bg-blue-100 focus:outline-none"></td>
                            </tr>
                        </table>
                        <div class="flex justify-end pr-3 mb-3">
                            <button class="bg-green-500 text-white px-6 py-2 rounded">Bayar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { reactive, ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core';
import { useRoute } from 'vue-router';
export default {

    setup() {
        
        let payment = reactive({
            nominal: '',
            date: ''
        });

        let product = ref({});

        const route = useRoute();

        onMounted(()=> {
            axios.get(`/api/v1/producs/${route.params.id}`)
            .then((result) => {
                console.log(result.data);
                product.value = result.data;
            }).catch((err) => {
                
            });
        });

        return {
            payment,
            product
        }

    }
    
}
</script>