<template>
  <!--Container-->
	<div class="container shadow-lg mx-auto bg-white mt-24 md:mt-18 px-2 py-3">
    <div>
        <div class="mb-3">
            <h1 class="text-lg">
                Daftar Clients
            </h1>
        </div>
      <router-link :to="{name: 'client.create'}" class="bg-green-500 rounded text-white px-3 py-2 hover:bg-green-300 inline-block mb-3">Buat Client Baru</router-link>

      <div class="overflow-x-scroll">
        <table>
          <thead class="font-bold">
            <tr>
              <td class="border border-gray-300 px-3 py-1">No</td>
              <td class="border border-gray-300 px-3 py-1">Nama</td>
              <td class="border border-gray-300 px-3 py-1">No Telpon</td>
              <td class="border border-gray-300 px-3 py-1">Pekerjaan</td>
              <td class="border border-gray-300 px-3 py-1">Alamat</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(client, index) in clients.data" :key="index">
              <td class="border border-gray-300 px-3 py-1">{{client.id}}</td>
              <td class="border border-gray-300 px-3 py-1 whitespace-nowrap">{{client.name}}</td>
              <td class="border border-gray-300 px-3 py-1">{{client.phone_number}}</td>
              <td class="border border-gray-300 px-3 py-1">{{client.job}}</td>
              <td class="border border-gray-300 px-3 py-1">{{client.address}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex justify-end mt-3 flex-wrap">
          <div v-for="(page, index) in clients.links" :key="index">
              <span v-on:click="page.url !== null ? getClient(page.url) : ''" class="border border-gray-400 px-3 py-1 mr-1 mb-1 inline-block rounded cursor-pointer" :class="page.active ? 'cursor-not-allowed bg-white' : 'bg-gray-300'">
                  <span v-html="page.label"></span>
              </span>
          </div>
      </div>
    </div>
	</div>
</template>

<script>

import axios from "axios";
import { onMounted, reactive, ref } from "vue"

export default {

  name: 'credit.index',

  setup() {

    // Reactive state
    let clients = ref([]);

    onMounted(() => {
      getClient(currentPage.url)
    });

    const currentPage = reactive({
        url: '/api/v1/clients',
    });

    function getClient(url) {
      // Get data from API
      axios.get(url)
      .then((result) => {
        clients.value = result.data;
      }).catch((err) => {
        console.log(err.response.message);
      });      
    }

    return { 
      clients,
      getClient ,
      currentPage
    }
  
  }


}
</script>