<template>
  <div>
    <div :class="isLoading ? '' : 'hidden'">
      <div class="fixed w-full h-screen bg-black bg-opacity-75 z-10">
        <div class="flex h-screen opacity w-full">
          <div class="m-auto md:w-1/2 w-full px-2 text-white text-center">
            Loading....
          </div>
        </div>
      </div>
    </div>
    <div :class="isLoading ? 'hidden' : ''">
      <NavBar/>
      <router-view/>
      <Footer/>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import Footer from '././components/Footer'
export default {
  name: 'Home',
  data() {
    return {
      isLoading: false
    }
  },
  mounted () {
    document.body.classList.add('bg-gray-400', 'login', 'font-sans', 'leading-normal', 'tracking-normal', 'text-gray-700', 'text-sm')
  },
  components: {
    NavBar,
    Footer,
  }
}
</script>

<style>

</style>