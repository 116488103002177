<template>
  <!--Container-->
	<div class="container shadow-lg mx-auto bg-white mt-24 md:mt-18 px-3">
    Ini Home
	</div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'Home',
}
</script>
