<template>
    <div class="container mx-auto mt-24 md:mt-18 px-3">
        <div class="flex h-screen center">
            <div class="m-auto md:w-1/3 w-full">
                <div class="bg-white shadow-lg px-3 py-2 rounded mb-32">
                    <div class="mb-3 py-3">
                        <h1 class="text-center text-lg font-bold">Tambah Dana</h1>
                    </div>
                    <form @submit.prevent="withdrawFunds">
                        <table class="mb-3">
                            <tr>
                                <td class="px-2">Tambah Ke</td>
                                <td>
                                    <div class="pb-1">
                                        <select v-model="transfer.wallet" class="px-3 py-2 rounded w-full bg-blue-100 focus:outline-none" @change="transferCompute">
                                            <option value="walletdefault" disabled>Pilih Tabungan</option>
                                            <option value="savings">Tabungan</option>
                                            <option value="capital">Modal</option>
                                        </select>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="transfer.available">
                                <td></td>
                                <td>
                                    <div class="pb-2 text-gray-400 italic">
                                        {{toRp(transfer.available)}}
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="validation.from">
                                <td></td>
                                <td class="text-red-500 pb-3">{{validation.from[0]}}</td>
                            </tr>

                            <tr>
                                <td class="px-2">Nominal</td>
                                <div class="pb-1">
                                    <td><input v-model="transfer.nominalRp" type="text" class="bg-blue-100 px-3 py-2 rounded" autocomplete="off" @input="transferCompute"></td>
                                </div>
                            </tr>

                            <tr>
                                <td class="px-2">Keterangan</td>
                                <div class="pb-1">
                                    <td>
                                        <textarea v-model="transfer.information" class="bg-blue-100 px-3 py-2 rounded w-full"></textarea>
                                    </td>
                                </div>
                            </tr>
                        </table>
                        <div class="flex justify-end pr-3 mb-3">
                            <button class="px-3 py-2 rounded" :class="transfer.nominal >= 10000 ? 'bg-green-500 hover:bg-green-400 text-white' : 'bg-gray-400 text-gray-300 pointer-events-none'">Masukan Dana</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>        
</template>
<script>
import axios from 'axios'
import { reactive, ref } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
import { useRouter } from 'vue-router';
export default {
    
    setup() {

        let transfer = reactive({
            wallet: 'capital',
            nominal: null,
            nominalRp: '',
            type: 'deposit',
            available: null,
            information: '',
        });

        const wallet = ref({
            total: '',
            savings: '',
            capital: ''
        });

        const validation = ref({});

        const router = useRouter();

        onMounted(()=>{
            getSaldo();
        });

        function transferCompute() {
            validation.value = '';
            if (transfer.wallet === 'savings') {
                transfer.available = this.wallet.savings
            }
            if (transfer.wallet === 'capital') {
                transfer.available = this.wallet.capital
            }
            if (transfer.nominalRp !== '') {
                transfer.nominal = transfer.nominalRp.split('.').join("");
                transfer.nominal = parseInt(transfer.nominal);
                transfer.nominalRp = transfer.nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            } else {
                transfer.nominal = 0;
            }

        }

        function getSaldo() {

            axios.get('/api/v1/saldos')
            .then((result) => {
                wallet.value.total = result.data.total;
                wallet.value.savings = result.data.savings;
                wallet.value.capital = result.data.capital;

                transfer.available = result.data.capital;

            }).catch((err) => {
                console.log(err.response);
            });
        }

        function withdrawFunds() {
            axios.get('/sanctum/csrf-cookie')
            axios.post('/api/v1/saldos', transfer)
            .then(() => {
                router.push({
                    name: 'saldo.index'
                });                
            }).catch((err) => {
                validation.value = err.response.data.errors;
            });
        }

        function toRp(nominal) {
            return new Intl.NumberFormat('id-ID', {
                style: 'currency', 
                currency: 'IDR' 
            }).format(nominal);
        }

        return {
            withdrawFunds,
            transfer,
            transferCompute,
            wallet,
            toRp,
            validation
        }
    }

}
</script>